import React from "react";
import { Message } from "../../state/chat";
import DOMPurify from "dompurify";

export function ChatMessage({
  message,
}: {
  message: Message;
  onImageLoaded?: () => void;
}) {
  const sanitizedHtml = DOMPurify.sanitize(message.message);
  // make links clickable, but only if they are not already wrapped in an <img> tag

  const messageWithLinks = sanitizedHtml
    // Split by <img> tags, process only the non-img sections
    .split(/(<img[^>]*>)/g)
    .map((part) => {
      // Skip if it's an <img> tag
      if (part.startsWith("<img")) {
        return part;
      }

      // Otherwise, replace URLs with links
      return part.replace(
        /((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/g,
        (match, p1, p2) => {
          return `<a href='${
            p2 ? "" : "http://"
          }${match}' target='_blank' rel='noopener noreferrer' class='underline hover:text-white'>${match}</a>`;
        },
      );
    })
    // Join all parts back together
    .join("");

  return (
    <li key={message.id} className="hover:bg-black/5 relative group py-3 px-6">
      <div className="flex justify-between items-center">
        <p className="text-sm font-semibold leading-6 text-white">
          {message.user}
        </p>
        <div className="flex overflow-hidden items-center">
          {message.song?.title && (
            <p
              title={message.song?.title}
              className="text-xs font-medium mx-2 px-1 py-0.5 rounded bg-gray-700 text-gray-300 truncate hidden group-hover:block"
            >
              {message.song?.title}
            </p>
          )}
          <p className="text-xs leading-6 text-gray-400">{message.timestamp}</p>
        </div>
      </div>
      <div
        className="mt-1 leading-5 text-gray-400 break-words"
        dangerouslySetInnerHTML={{
          __html: messageWithLinks,
        }}
      />
    </li>
  );
}
