import { ChatMessage, SendChatMessage, StuenMessage } from "common";
import { ws } from "./api";
import { useMockApi, userName } from "../state/global";
import { messages } from "../state/chat";
import moment from "moment";
import { currentSong } from "../state/player";

export const handleChatEvents = (socketMessage: StuenMessage) => {
  if (socketMessage instanceof ChatMessage) {
    messages.value = [
      ...messages.value,
      {
        id: crypto.randomUUID(),
        user: socketMessage.name,
        message: socketMessage.message,
        timestamp: moment().format("HH.mm"),
        song: currentSong.value,
      },
    ];
  }
};

export const sendChatMessage = (message: string) => {
  if (useMockApi.value) {
    messages.value = [
      ...messages.value,
      {
        id: crypto.randomUUID(),
        user: userName.value || "Test",
        message: message,
        timestamp: moment().format("HH.mm"),
        song: currentSong.value,
      },
    ];
  } else {
    ws?.send(JSON.stringify(new SendChatMessage(message)));
  }
};
