import React from "react";
import { closeSongPreview, songPreviewSong } from "../state/songPreview";
import { Dialog } from "@headlessui/react";
import { queueSong } from "../api/song";
import YouTube, { YouTubePlayer } from "react-youtube";

export function SongPreview() {
  const [startedAtZero, setStartedAtZero] = React.useState(false);
  const handlePlayerReady = (event: { target: YouTubePlayer }) => {
    event.target.playVideo();
  };

  const handleStartAtZero = (event: { target: YouTubePlayer }) => {
    if (!startedAtZero) {
      setStartedAtZero(true);
      event.target.seekTo(0, true);
    }
  };

  return songPreviewSong.value ? (
    <Dialog
      open={!!songPreviewSong.value}
      onClose={closeSongPreview}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex w-screen mt-10 justify-center items-start p-4">
        <Dialog.Panel className="mx-auto max-w-lg w-1/2 rounded-xl bg-slate-700 h-auto drop-shadow-md shadow-xl">
          <Dialog.Title
            className={
              "text-white text-lg truncate font-semibold bg-slate-800 rounded-t-xl py-3 px-6 drop-shadow shadow"
            }
          >
            {songPreviewSong.value.title}
          </Dialog.Title>
          <div className="p-3">
            <div className="video-responsive">
              <YouTube
                videoId={songPreviewSong.value.songId}
                opts={{
                  width: "100%",
                  height: "auto",
                  playerVars: {
                    autoplay: 1,
                    start: 0,
                  },
                }}
                onReady={handlePlayerReady}
                onPlay={handleStartAtZero}
              />
            </div>
            <div className="text-right mt-3">
              <button
                onClick={() => {
                  if (songPreviewSong.value) {
                    queueSong(songPreviewSong.value);
                  }
                  closeSongPreview();
                }}
                className="bg-green-700 rounded-md pr-3 pl-1 py-1 mx-3"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 inline-block -mt-0.5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v12m6-6H6"
                  />
                </svg>
                Queue
              </button>
              <button
                onClick={closeSongPreview}
                className="bg-sky-700 rounded-md px-3 py-1"
              >
                Close
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  ) : null;
}
