import React from "react";
import { Playlist, Song } from "common";
import { PlusIcon, VideoCameraIcon } from "@heroicons/react/20/solid";
import { queueSong } from "../../api/song";
import { openSongPreview } from "../../state/songPreview";
import { removeSongFromPlaylist } from "../../api/playlist";
import { userName } from "../../state/global";

export function PlaylistSongListItem({
  song,
  playlistId,
  playlistListener,
}: {
  song: Song;
  playlistId: Playlist["title"];
  playlistListener: Playlist["listener"];
}) {
  const ref = React.useRef<HTMLLIElement>(null);

  return (
    <li
      ref={ref}
      className="flex justify-between gap-x-6 py-1.5 group relative pl-2 items-center hover:bg-white/5"
    >
      <div className="flex min-w-0 gap-x-2 items-center w-full">
        <img
          className="h-12 w-[64px] flex-none drop-shadow-lg shadow-lg bg-gray-800"
          src={song.image}
          alt=""
        />
        <div className="w-full flex-auto">
          <p className="text-sm font-semibold leading-6 text-white line-clamp-2">
            {song.title}
          </p>
        </div>
        <div className="text-right whitespace-nowrap">
          <button
            className="bg-green-700 hover:bg-green-600 rounded-full p-1 mr-2 mt-[2px]"
            onClick={() => queueSong(song)}
          >
            <PlusIcon className="h-4 w-4" aria-hidden="true" />
          </button>
          <button
            className="bg-sky-600 hover:bg-sky-500 rounded-full p-1 mr-2 mt-[2px]"
            onClick={() => openSongPreview(song)}
          >
            <VideoCameraIcon className="h-4 w-4" aria-hidden="true" />
          </button>
          {playlistListener === userName.value && (
            <button
              className="bg-red-900 hover:bg-red-800 rounded-full p-1 mr-2 mt-[2px]"
              onClick={() => removeSongFromPlaylist(song, playlistId)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    </li>
  );
}
