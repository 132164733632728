import { computed, signal } from "@preact/signals-react";
import { Playlist, PlaylistDescription, Song } from "common";
import { userName } from "./global";

export const activePlaylistId = signal<string | null>(null);
export const playlistDescriptions = signal<PlaylistDescription[]>([]);
export const playlists = signal<Playlist[]>([]);
export const otherPlaylistDescriptions = signal<PlaylistDescription[]>([]);

export const ownPlaylists = computed(() =>
  playlists.value.filter((playlist) => playlist.listener === userName.value),
);

export const activePlaylist = computed(() => {
  const activeId = activePlaylistId.value;
  return (
    playlists.value.find(
      (playlist) => playlist.title + playlist.listener === activeId,
    ) ?? null
  );
});

export const getIsSongFavorited = (songId: Song["songId"]) => {
  const favoritePlaylist = playlists.value.find(
    (playlist) => playlist.title === "favorites",
  );
  return (
    favoritePlaylist?.songs.some((song) => song.songId === songId) ?? false
  );
};

export const removePlaylistsThatNoLongerExist = () => {
  playlists.value = playlists.value.filter((existingPlaylist) =>
    playlistDescriptions.value.some((p) => p.title === existingPlaylist.title),
  );
};
