import React from "react";
import { Song } from "common";
import { SongListItem } from "./songListItem";

export function SongList({ songs = [] }: { songs?: Song[] }) {
  return (
    <ul className="divide-y divide-slate-700/50">
      {songs.map((song) => (
        <SongListItem key={song.songId} song={song} />
      ))}
    </ul>
  );
}
