import {
  AddSongToPlaylist,
  CreatePlaylist,
  GetPlaylist,
  Playlist,
  PlaylistDescription,
  PlaylistSongs,
  Playlists,
  RemovePlaylist,
  RemoveSongFromPlaylist,
  Song,
  StuenMessage,
  GetOtherPlaylists,
  OthersPlaylists,
} from "common";
import { ws } from "./api";
import { useMockApi } from "../state/global";
import {
  otherPlaylistDescriptions,
  playlistDescriptions,
  playlists,
  removePlaylistsThatNoLongerExist,
} from "../state/playlists";
import _ from "lodash";

export const handlePlaylistEvents = (socketMessage: StuenMessage) => {
  if (socketMessage instanceof Playlists) {
    playlistDescriptions.value = socketMessage.playlists;
    if (!socketMessage.playlists.some((p) => p.title === "favorites")) {
      createPlaylist("favorites");
    }
    // Fetch songs for each playlist
    socketMessage.playlists.forEach((playlist) => {
      getPlaylistSongs(playlist);
    });
    removePlaylistsThatNoLongerExist();
  }
  if (socketMessage instanceof PlaylistSongs) {
    const title = socketMessage.playlist.title;
    playlists.value = _.sortBy(
      [
        ...playlists.value.filter(
          (playlist) =>
            playlist.title !== title ||
            playlist.listener !== socketMessage.playlist.listener,
        ),
        socketMessage.playlist,
      ],
      (p) => {
        // sort favorites first, then by title alphabetically
        if (p.title === "favorites") return "0";
        return p.title;
      },
    );
  }
  if (socketMessage instanceof OthersPlaylists) {
    const filteredAndSorted = socketMessage.playlists
      .filter((p) => p.title !== "favorites")
      .sort((a, b) => a.listener.localeCompare(b.listener));
    otherPlaylistDescriptions.value = filteredAndSorted;
    filteredAndSorted.forEach((playlist) => {
      getPlaylistSongs(playlist);
    });
  }
};

export const createPlaylist = (playlistId: Playlist["title"]) => {
  if (useMockApi.value) {
  } else {
    ws?.send(JSON.stringify(new CreatePlaylist(playlistId)));
  }
};

export const removePlaylist = (playlistId: Playlist["title"]) => {
  if (useMockApi.value) {
  } else {
    ws?.send(JSON.stringify(new RemovePlaylist(playlistId)));
  }
};

export const addSongToPlaylist = (
  song: Song,
  playlistId: Playlist["title"],
) => {
  if (useMockApi.value) {
  } else {
    ws?.send(JSON.stringify(new AddSongToPlaylist(song, playlistId)));
  }
};

export const removeSongFromPlaylist = (
  song: Song,
  playlistId: Playlist["title"],
) => {
  if (useMockApi.value) {
  } else {
    ws?.send(JSON.stringify(new RemoveSongFromPlaylist(song, playlistId)));
  }
};

export const getPlaylistSongs = (playlist: PlaylistDescription) => {
  if (useMockApi.value) {
  } else {
    ws?.send(JSON.stringify(new GetPlaylist(playlist)));
  }
};

export const getOthersPlaylists = () => {
  if (useMockApi.value) {
  } else {
    ws?.send(JSON.stringify(new GetOtherPlaylists()));
  }
};
