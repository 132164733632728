import { effect, signal } from "@preact/signals-react";

export const userName = signal<string | null>(
  localStorage.getItem("stuenUserName") || null,
);
export const listeners = signal<string[]>([]);
export const videoCannotPlay = signal<boolean>(false);

export const useMockApi = signal<boolean>(false);
export const connectionState = signal<
  "Connecting" | "Connected" | "Disconnected"
>("Connecting");

effect(() => {
  if (userName.value) {
    localStorage.setItem("stuenUserName", userName.value);
  } else {
    localStorage.removeItem("stuenUserName");
  }
});
