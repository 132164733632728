import { Song } from "common";
import React from "react";
import { StarIcon as StarIconOutlined } from "@heroicons/react/24/outline";
import { StarIcon as StarIconSolid } from "@heroicons/react/24/solid";
import { addSongToFavorites, removeSongFromFavorites } from "../../api/song";
import { getIsSongFavorited } from "../../state/playlists";

export function ToggleFavoriteSongButton({ song }: { song: Song }) {
  const isSongFavorited = getIsSongFavorited(song.songId);
  return (
    <button
      onClick={() =>
        isSongFavorited
          ? removeSongFromFavorites(song)
          : addSongToFavorites(song)
      }
      className="bg-slate-700 rounded-md py-2 px-3 whitespace-nowrap text-sm mr-2"
    >
      {isSongFavorited ? (
        <StarIconSolid className="w-6 h-6 ml-1 inline-block -mt-1" />
      ) : (
        <StarIconOutlined className="w-6 h-6 ml-1 inline-block -mt-1" />
      )}
    </button>
  );
}
