import React from "react";
import { HandThumbUpIcon as HandThumbUpIconOutlined } from "@heroicons/react/24/outline";
import { HandThumbUpIcon as HandThumbUpIconSolid } from "@heroicons/react/24/solid";
import { toggleLikeSong } from "../../api/song";
import clsx from "clsx";
import { songLikes } from "../../state/songLikes";
import { userName } from "../../state/global";
import { Tooltip, TooltipContent, TooltipTrigger } from "../tooltip";

export function ToggleLikeSongButton() {
  const isSongLiked =
    userName.value && songLikes.value.includes(userName.value);
  const likesCount = songLikes.value.length;
  return (
    <div className="relative inline-block">
      <Tooltip>
        <TooltipTrigger
          onClick={toggleLikeSong}
          className={clsx(
            "bg-slate-700 rounded-md py-2 px-3 whitespace-nowrap text-sm mr-2",
            isSongLiked && "text-green-400",
          )}
        >
          {likesCount > 0 && likesCount}
          {isSongLiked ? (
            <HandThumbUpIconSolid className="w-6 h-6 ml-1 inline-block -mt-1 " />
          ) : (
            <HandThumbUpIconOutlined className="w-6 h-6 ml-1 inline-block -mt-1" />
          )}
        </TooltipTrigger>
        {likesCount > 0 && (
          <TooltipContent>
            <div className="border border-slate-600 rounded-lg max-w-lg bg-slate-700  text-green-400 z-50 px-3 py-1">
              <ul className="text-sm">
                {songLikes.value.map((listener) => (
                  <li className="flex my-1" key={listener}>
                    <HandThumbUpIconSolid className="w-4 h-4 mr-1.5 mt-0.5 inline-block" />
                    {listener}
                  </li>
                ))}
              </ul>
            </div>
          </TooltipContent>
        )}
      </Tooltip>
    </div>
  );
}
