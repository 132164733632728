import React from "react";
import { Song } from "common";
import { queueSong } from "../../api/song";
import { openSongPreview } from "../../state/songPreview";
import { Popover, PopoverContent, PopoverTrigger } from "../popover";
import { ownPlaylists } from "../../state/playlists";
import { addSongToPlaylist, removeSongFromPlaylist } from "../../api/playlist";
import clsx from "clsx";
import { CheckIcon } from "@heroicons/react/20/solid";

export function SongListItem({ song }: { song: Song }) {
  const [showAddToPlaylist, setShowAddToPlaylist] = React.useState(false);
  const [showOverlay, setShowOverlay] = React.useState(false);

  const handleAddSongToPlaylist = (playlistTitle: string) => {
    addSongToPlaylist(song, playlistTitle);
  };

  const handleRemoveSongFromPlaylist = (playlistTitle: string) => {
    removeSongFromPlaylist(song, playlistTitle);
  };

  const handlePopoverOpenChanged = (open: boolean) => {
    setShowAddToPlaylist(open);
    setShowOverlay(open);
  };

  return (
    <>
      <li
        key={song.songId}
        className="flex justify-between gap-x-6 py-5 group relative px-3"
        onMouseEnter={() => setShowOverlay(true)}
        onMouseLeave={() => !showAddToPlaylist && setShowOverlay(false)}
      >
        <div className="flex w-full gap-x-4 relative">
          <img
            className="h-12 w-12 flex-none rounded-full drop-shadow-lg shadow-lg bg-gray-800"
            src={song.image}
            alt=""
          />
          {song.inAutoPlay && (
            <CheckIcon className="absolute top-7 p-0.5 right-0 w-4 h-4 font-bold text-white bg-green-800 rounded-full" />
          )}
          <div className="min-w-0 flex-auto">
            <p className="text-sm font-semibold leading-6 text-white truncate">
              {song.title}
            </p>
            <p className="mt-1 pr-3 truncate text-xs leading-5 text-gray-400">
              https://www.youtube.com/watch?v={song.songId}
            </p>
          </div>
        </div>
        <div
          className={clsx(
            "absolute top-0 bottom-0 left-0 right-0 bg-white/20 text-center z-10 rounded-xl",
            showOverlay ? "block" : "hidden",
          )}
        >
          <div className="p-1 bg-slate-700 absolute top-[50%] right-1 -translate-y-1/2 rounded-xl shadow-sm">
            <div className="flex text-center gap-x-1">
              <Popover
                open={showAddToPlaylist}
                onOpenChange={handlePopoverOpenChanged}
                placement="bottom"
              >
                <PopoverTrigger
                  as="div"
                  onClick={() => setShowAddToPlaylist((v) => !v)}
                  className="cursor-pointer hover:bg-slate-600 pt-2 px-2 rounded-md group relative"
                >
                  <button className="bg-yellow-600 rounded-full p-1  block mx-auto">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 10.5v6m3-3H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                      />
                    </svg>
                  </button>

                  <span className="text-xs text-slate-300">Playlist</span>
                </PopoverTrigger>
                <PopoverContent className="border border-slate-600 rounded-lg w-40 bg-slate-700  text-white -mt-1 z-20 py-1">
                  {ownPlaylists.value.map((playlist) => {
                    const isSongInPlaylist = playlist.songs.some(
                      (s) => song.songId === s.songId,
                    );
                    return (
                      <button
                        key={playlist.title + playlist.listener}
                        onClick={() =>
                          isSongInPlaylist
                            ? handleRemoveSongFromPlaylist(playlist.title)
                            : handleAddSongToPlaylist(playlist.title)
                        }
                        type="button"
                        className="relative inline-flex items-center w-full px-2 py-1 text-sm font-medium rounded-lg focus:z-10 hover:bg-slate-600/50 hover:text-white focus:text-white whitespace-nowrap uppercase"
                      >
                        <span className="flex items-center gap-2 max-w-full">
                          <div className="w-4 h-4 inline-block border border-slate-500 rounded flex-shrink-0">
                            {isSongInPlaylist && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-4 h-4 inline-block text-white -mt-2 -ml-[1px]"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m4.5 12.75 6 6 9-13.5"
                                />
                              </svg>
                            )}
                          </div>

                          <span className="truncate">{playlist.title}</span>
                        </span>
                      </button>
                    );
                  })}
                </PopoverContent>
              </Popover>

              <div
                onClick={() => openSongPreview(song)}
                className="cursor-pointer hover:bg-slate-600 pt-2 px-2 rounded-md group"
              >
                <button className="bg-sky-600 rounded-full p-1  block mx-auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"
                    />
                  </svg>
                </button>
                <span className="text-xs text-slate-300">Preview</span>
              </div>
              <div
                onClick={() => queueSong(song)}
                className="cursor-pointer hover:bg-slate-600 pt-2 px-2 rounded-md group"
              >
                <button className="bg-green-700 rounded-full p-1  block mx-auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>
                </button>
                <span className="text-xs text-slate-300">Queue</span>
              </div>
            </div>
          </div>
        </div>
      </li>
    </>
  );
}
