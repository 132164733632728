import React from "react";
import {
  MagnifyingGlassIcon,
  PlusIcon,
  StarIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import { activePlaylistId, ownPlaylists } from "../../state/playlists";
import { createPlaylist } from "../../api/playlist";
import { Dialog } from "@headlessui/react";
import { OtherPlaylistButton } from "./otherPlaylistButton";

export function Playlists() {
  const [showCreatePlaylistModal, setShowCreatePlaylistModal] =
    React.useState(false);
  const [newPlaylistName, setNewPlaylistName] = React.useState("");

  const handleShowCreatePlaylistModal = () => {
    setNewPlaylistName("");
    setShowCreatePlaylistModal(true);
  };

  const handleAddPlaylist = (e: React.FormEvent) => {
    e.preventDefault();
    if (newPlaylistName === "") return;
    createPlaylist(newPlaylistName);
    setShowCreatePlaylistModal(false);
  };

  return (
    <>
      <div className="hidden md:block h-full overflow-auto max-h-full flex-[0_0_50px] bg-gray-900/50 text-center">
        <button
          type="button"
          onClick={() => {
            activePlaylistId.value = null;
          }}
          className={clsx(
            "max-w-full mt-3 block mx-auto text-center rounded-lg px-2 pb-2 pt-1 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
            activePlaylistId.value === null
              ? "bg-sky-600 hover:bg-sky-700"
              : "bg-slate-700/50 hover:bg-slate-700",
          )}
        >
          <MagnifyingGlassIcon
            className="h-4 w-4 inline-block"
            aria-hidden="true"
          />
        </button>
        {ownPlaylists.value.map((playlist) => (
          <button
            key={playlist.title + playlist.listener}
            type="button"
            onClick={() => {
              activePlaylistId.value =
                activePlaylistId.value === playlist.title + playlist.listener
                  ? null
                  : playlist.title + playlist.listener;
            }}
            className={clsx(
              "max-w-full mt-3 block mx-auto text-center rounded-lg px-2 pb-2 pt-1 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
              activePlaylistId.value === playlist.title + playlist.listener
                ? "bg-sky-600 hover:bg-sky-700"
                : "bg-slate-700/50 hover:bg-slate-700",
            )}
          >
            {playlist.title === "favorites" && (
              <StarIcon className="h-4 w-4 inline-block" aria-hidden="true" />
            )}
            {playlist.title !== "favorites" && (
              <div
                className="text-sm truncate uppercase pt-1 font-semibold"
                title={playlist.title}
              >
                {playlist.title.slice(0, 2)}
              </div>
            )}
          </button>
        ))}

        <OtherPlaylistButton />
        <button
          type="button"
          className="mt-3 rounded-lg bg-slate-700/50 p-2 text-white shadow-sm hover:bg-slate-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleShowCreatePlaylistModal}
        >
          <PlusIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      {showCreatePlaylistModal && (
        <Dialog
          open={showCreatePlaylistModal}
          onClose={() => setShowCreatePlaylistModal(false)}
          className="relative z-50"
        >
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          {/* Full-screen container to center the panel */}
          <div className="fixed inset-0 flex w-screen mt-10 justify-center items-start p-4">
            <Dialog.Panel className="mx-auto max-w-lg w-1/2 rounded-xl bg-slate-700 h-auto drop-shadow-md shadow-xl">
              <Dialog.Title
                className={
                  "text-white text-lg truncate font-semibold bg-slate-800 rounded-t-xl py-3 px-6 drop-shadow shadow"
                }
              >
                Create new playlist
              </Dialog.Title>
              <div className="p-3">
                <form onSubmit={handleAddPlaylist}>
                  <input
                    value={newPlaylistName}
                    onChange={(e) => setNewPlaylistName(e.target.value)}
                    type="text"
                    className="block w-full p-2 pr-44 text-sm border rounded-lg bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-sky-500 focus:border-sky-500"
                    placeholder="Playlist name"
                    required
                    autoComplete="off"
                  />
                  <div className="flex justify-end mt-3">
                    <button
                      onClick={() => setShowCreatePlaylistModal(false)}
                      type="button"
                      className="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-1 bg-slate-600 hover:bg-slate-500 focus:ring-slate-900"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="text-white ml-3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-1 bg-sky-700 hover:bg-sky-600 focus:ring-sky-900"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
      )}
    </>
  );
}
