import React, { useCallback, useEffect, useRef } from "react";
import YouTube, { YouTubePlayer } from "react-youtube";
import { isAnyPreviewOpen } from "../../state/songPreview";
import { videoCannotPlay } from "../../state/global";
import {
  currentSongPlayerMuted,
  currentSongStart,
  currentSongUniqueId,
  currentSongUrl,
} from "../../state/player";

export function CurrentSongVideo() {
  const [startedAtStart, setStartedAtStart] = React.useState(false);
  const playerRef = useRef<YouTubePlayer | null>(null);
  const [playStarted, setPlayStarted] = React.useState(false);
  const muted = currentSongPlayerMuted.value;
  const url = currentSongUrl.value;
  const start = currentSongStart.value;
  const isPreviewOpen = isAnyPreviewOpen.value;
  const id = currentSongUniqueId.value; // This is used to force the video to re-render when the song changes, even when the song is the same as the previous one

  const handleSetPlayerMutedState = useCallback(
    (player: YouTubePlayer | null) => {
      if (player) {
        if (muted || isPreviewOpen) {
          player.mute();
        } else {
          player.unMute();
        }
      }
    },
    [muted, isPreviewOpen],
  );

  useEffect(() => {
    /* Set player mute state when:
     * - the song changes (because the player changes too)
     * - the preview is opened or closed
     * - the user changes the mute state
     */
    handleSetPlayerMutedState(playerRef.current);
  }, [muted, playerRef, isPreviewOpen, handleSetPlayerMutedState]);

  useEffect(() => {
    if (playStarted) {
      videoCannotPlay.value = false;
    }
  }, [playStarted]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (url && !playStarted) {
      // check after 5 seconds - if the video didn't start playing, there is probably a browser setting blocking autoplay
      timer = setTimeout(() => {
        if (!playStarted) {
          videoCannotPlay.value = true;
        }
      }, 5000);
    }

    return () => clearTimeout(timer);
  }, [playerRef, playStarted, url]);

  const handlePlayerReady = (event: { target: YouTubePlayer }) => {
    if (!!playerRef.current?.isMuted() !== muted) {
      // Compare the old player muted state with the global muted state
      // Update the global muted state if they are not the same
      // Done here, because there is no event for mute state changes in the youtube API
      currentSongPlayerMuted.value = !!playerRef.current?.isMuted();
    }
    setStartedAtStart(false); // Reset the state
    playerRef.current = event.target;
    // new player, so we need to set the mute state on the new player
    handleSetPlayerMutedState(event.target);
    event.target.playVideo();
  };

  const handlePlay = (event: { target: YouTubePlayer }) => {
    setPlayStarted(true);
    if (!startedAtStart) {
      setStartedAtStart(true);
      event.target.seekTo(start, true);
    }
  };

  return url ? (
    <div className="video-responsive">
      <YouTube
        key={id}
        videoId={url}
        opts={{
          width: "853",
          height: "480",
          playerVars: {
            autoplay: 1,
            start: start,
          },
        }}
        onReady={handlePlayerReady}
        onPlay={handlePlay}
      />
    </div>
  ) : (
    <div className="aspect-video bg-black justify-center items-center flex font-semibold">
      The queue is empty
    </div>
  );
}
