import { Join, ListenerList, StuenMessage } from "common";
import { listeners, userName } from "../state/global";
import { ws } from "./api";

export const handleListenerEvents = (socketMessage: StuenMessage) => {
  if (socketMessage instanceof ListenerList) {
    listeners.value = socketMessage.names;
  }
};

export const joinListener = () => {
  if (userName.value) {
    ws?.send(JSON.stringify(new Join(userName.value)));
  }
};
