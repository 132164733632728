import { effect, signal } from "@preact/signals-react";
import { PlayingSong } from "common";

export const currentSongUrl = signal<string | null>(null);
export const currentSongStart = signal<number>(0);
export const currentSongUniqueId = signal<string | null>(null); // Used to force reload of video element when two songs have the same url
export const currentSong = signal<PlayingSong | null>(null);
export const currentSongPlayerMuted = signal<boolean>(false);

effect(() => {
  if (currentSong.value) {
    document.title = currentSong.value.title;
  } else {
    document.title = `Stuen`;
  }
});
