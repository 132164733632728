import clsx from "clsx";
import React from "react";

export function PrivacyPolicy({
  linkColor = "text-gray-500 hover:text-gray-400",
}: {
  linkColor?: string;
}) {
  return (
    <>
      This site uses YouTube API Services to search YouTube. It does not use any
      user specific data from YouTube.
      <br />
      <a
        href="http://www.google.com/policies/privacy"
        target="_blank"
        rel="noreferrer"
        className={clsx("underline", linkColor)}
      >
        Google Privacy Policy
      </a>
      <br />
      References to played videos are stored to allow the system to cycle
      through previously played videos. These references have no user data
      associated with them.
      <br />
      Your username is stored and it is used to:
      <ul className="list-disc pl-3">
        <li>Display to all users that you are online.</li>
        <li>
          It will be shown alongside any video that you add to the video queue.
        </li>
        <li>
          It will be displayed in the chat, if you choose to write in the chat.
        </li>
        <li>
          It will be displayed alongside a like button, if you choose to like a
          song.
        </li>
        <li>
          Keep track of any playlists that you choose to create. Playlists will
          be shared with all users.
        </li>
      </ul>
      Contact information: stuen@tjb.dk
    </>
  );
}
