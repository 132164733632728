import { computed, signal } from "@preact/signals-react";
import { Song } from "common";

export const songPreviewSong = signal<Song | null>(null);

export const closeSongPreview = () => {
  songPreviewSong.value = null;
};

export const openSongPreview = (song: Song) => {
  songPreviewSong.value = song;
};

export const isAnyPreviewOpen = computed(() => {
  return songPreviewSong.value !== null;
});
