import React from "react";
import { activePlaylist, activePlaylistId } from "../../state/playlists";
import {
  EllipsisHorizontalIcon,
  StarIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { PlaylistSongList } from "./playlistSongList";
import { Popover, PopoverContent, PopoverTrigger } from "../popover";
import { queueSongs } from "../../api/song";
import { removePlaylist } from "../../api/playlist";
import { Dialog } from "@headlessui/react";
import { userName } from "../../state/global";

export function PlaylistView() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);

  const handleQueueAll = () => {
    if (activePlaylist.value) {
      queueSongs(activePlaylist.value.songs);
    }
  };

  const handleRemovePlaylist = () => {
    if (activePlaylist.value) {
      removePlaylist(activePlaylist.value.title);
      setShowConfirmDelete(false);
      activePlaylistId.value = null;
    }
  };

  const handleClose = () => {
    activePlaylistId.value = null;
    setIsOpen(false);
  };

  return activePlaylist.value ? (
    <>
      <div className="overflow-y-auto drop-shadow-sm shadow-md box-border relative">
        <div className="flex border border-slate-700 box-border bg-white/10">
          <h3
            className="text-base font-semibold leading-6 my-3 ml-3 text-center flex-grow truncate uppercase"
            title={activePlaylist.value.title}
          >
            {activePlaylist.value.title === "favorites" && (
              <StarIcon className="h-5 w-5 inline-block mr-2 -mt-1" />
            )}
            {activePlaylist.value.title}
            {activePlaylist.value.listener !== userName.value && (
              <span className="text-xs font-normal">
                {" "}
                - By {activePlaylist.value.listener}
              </span>
            )}
          </h3>
          <Popover
            open={isOpen}
            onOpenChange={setIsOpen}
            placement="bottom-end"
          >
            <PopoverTrigger
              onClick={() => setIsOpen((v) => !v)}
              className="bg-slate-600 hover:bg-slate-500 rounded-full whitespace-nowrap mr-2 mt-3 items-center justify-center relative h-6 w-6"
            >
              <EllipsisHorizontalIcon className="w-4 h-4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
            </PopoverTrigger>
            <PopoverContent className="border border-slate-600 rounded-lg bg-slate-700  text-white w-40">
              <button
                onClick={handleQueueAll}
                type="button"
                className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium rounded-lg focus:z-10 hover:bg-slate-600 hover:text-white focus:text-white whitespace-nowrap"
              >
                Queue all songs
              </button>
              {activePlaylist.value.listener === userName.value &&
                activePlaylist.value.title !== "favorites" && (
                  <button
                    onClick={() => setShowConfirmDelete(true)}
                    type="button"
                    className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium rounded-lg focus:z-10 hover:bg-slate-600 hover:text-white focus:text-white whitespace-nowrap"
                  >
                    Delete playlist
                  </button>
                )}
            </PopoverContent>
          </Popover>
          <button
            className="bg-slate-600 hover:bg-slate-500 rounded-full whitespace-nowrap mr-2 mt-3 items-center justify-center relative h-6 w-6"
            onClick={handleClose}
          >
            <XMarkIcon className="w-4 h-4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
          </button>
        </div>
        <div className="overflow-y-auto h-[calc(100%-50px)]">
          {activePlaylist.value.songs.length === 0 && (
            <div className="text-center text-gray-400 text-sm p-4 font-semibold">
              This playlist is empty
            </div>
          )}
          <PlaylistSongList playlist={activePlaylist.value} />
        </div>
      </div>
      {showConfirmDelete && (
        <Dialog
          open={showConfirmDelete}
          onClose={() => setShowConfirmDelete(false)}
          className="relative z-50"
        >
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          {/* Full-screen container to center the panel */}
          <div className="fixed inset-0 flex w-screen mt-10 justify-center items-start p-4">
            <Dialog.Panel className="mx-auto max-w-lg w-1/2 rounded-xl bg-slate-700 h-auto drop-shadow-md shadow-xl">
              <Dialog.Title
                className={
                  "text-white text-center text-lg truncate font-semibold bg-slate-800 rounded-t-xl py-3 px-6 drop-shadow shadow"
                }
              >
                Delete playlist
              </Dialog.Title>
              <div className="px-6 py-4">
                <p>
                  Are you sure you want to delete the playlist "
                  {activePlaylist.value.title}"?
                </p>
                <div className="flex justify-end mt-3">
                  <button
                    onClick={() => setShowConfirmDelete(false)}
                    type="submit"
                    className="text-white focus:outline-none font-medium rounded-lg text-sm px-6 py-2 bg-slate-600 hover:bg-slate-500 "
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={handleRemovePlaylist}
                    className="text-white ml-3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-6 py-2 bg-red-700 hover:bg-red-600 focus:ring-sky-900"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
      )}
    </>
  ) : null;
}
