import React from "react";
import ReactDOM from "react-dom/client";
import "./globals.css";
import reportWebVitals from "./reportWebVitals";
import { AppWrapper } from "./AppWrapper";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <DndProvider backend={HTML5Backend}>
    <AppWrapper />
  </DndProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
