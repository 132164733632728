import { Dialog } from "@headlessui/react";
import React from "react";
import { PrivacyPolicy } from "./privacyPolicy";

export function Tos() {
  const [showTos, setShowTos] = React.useState(false);
  return (
    <>
      <div
        className="text-xs underline underline-offset-2 cursor-pointer text-slate-200 hover:text-white"
        onClick={() => setShowTos(true)}
      >
        ToS and Privacy Policy
      </div>
      <Dialog
        open={showTos}
        onClose={() => setShowTos(false)}
        className="relative z-50"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex w-screen mt-10 justify-center items-start p-4">
          <Dialog.Panel className="mx-auto max-w-2xl w-1/2 rounded-xl bg-slate-700 h-auto drop-shadow-md shadow-xl">
            <Dialog.Title
              className={
                "text-white text-lg truncate font-semibold bg-slate-800 rounded-t-xl py-3 px-6 drop-shadow shadow"
              }
            >
              ToS and Privacy Policy
            </Dialog.Title>
            <div className="p-6">
              <p className="font-semibold mb-3">Terms of Service</p>
              <a
                href="https://www.youtube.com/t/terms"
                target="_blank"
                rel="noreferrer"
                className="underline text-slate-300 hover:text-white"
              >
                YouTube Terms of Service
              </a>
              <p className="font-semibold mb-3 mt-6">Privacy Policy</p>
              <p className="text-slate-300">
                <PrivacyPolicy linkColor={"text-slate-300 hover:text-white"} />
              </p>
              <div className="text-right">
                <button
                  onClick={() => setShowTos(false)}
                  className="px-4 py-2 bg-sky-600 rounded-lg"
                >
                  Close
                </button>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
}
