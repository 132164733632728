import React, { useEffect } from "react";
import "./signIn.css";
import { userName } from "./state/global";
import { PrivacyPolicy } from "./components/privacyPolicy";

export function SignIn() {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [tosAgreed, setTosAgreed] = React.useState(false);
  const [privacyAgreed, setPrivacyAgreed] = React.useState(false);
  const [showError, setShowError] = React.useState(false);

  useEffect(() => {
    if (tosAgreed) {
      setShowError(false);
    }
  }, [tosAgreed]);

  useEffect(() => {
    if (privacyAgreed) {
      setShowError(false);
    }
  }, [privacyAgreed]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowError(false);
    if (!tosAgreed || !privacyAgreed) {
      setShowError(true);
    } else if (password === "hererstuen") {
      userName.value = username;
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-slate-900 sign-in-background">
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-lg bg-slate-800 p-6 rounded-xl drop-shadow-xl shadow-xl">
        <form
          className="space-y-6"
          action="#"
          method="POST"
          onSubmit={handleSubmit}
        >
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-white"
            >
              Username
            </label>
            <div className="mt-2">
              <input
                id="username"
                type="text"
                required
                onChange={(e) => setUsername(e.target.value)}
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-white"
              >
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                type="password"
                required
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          {showError && (
            <div
              className="p-4 mb-4 text-sm rounded-lg text-red-400 border-red-400 border flex"
              role="alert"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 mr-3"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>
              You must agree to the Terms of Service and Privacy Policy
            </div>
          )}
          <fieldset className="border-b border-t border-slate-700">
            <legend className="sr-only">Notifications</legend>
            <div className="divide-y divide-slate-700">
              <div className="relative flex items-start pb-4 pt-3.5">
                <div className="min-w-0 flex-1 text-sm leading-6">
                  <label
                    htmlFor="tos"
                    className="font-medium text-white cursor-pointer"
                  >
                    Agree to YouTube Terms of Service
                  </label>
                  <p id="tos-description" className="text-gray-500">
                    Before using this, you must agree to be bound by the{" "}
                    <a
                      href="https://www.youtube.com/t/terms"
                      target="_blank"
                      rel="noreferrer"
                      className="underline text-gray-500 hover:text-gray-400"
                    >
                      YouTube Terms of Service
                    </a>
                  </p>
                </div>
                <div className="ml-3 flex h-6 items-center">
                  <input
                    id="tos"
                    aria-describedby="tos-description"
                    name="tos"
                    type="checkbox"
                    checked={tosAgreed}
                    onChange={(e) => setTosAgreed(e.target.checked)}
                    className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                  />
                </div>
              </div>
              <div className="relative flex items-start pb-4 pt-3.5">
                <div className="min-w-0 flex-1 text-sm leading-6">
                  <label
                    htmlFor="privacy"
                    className="font-medium text-white cursor-pointer"
                  >
                    Agree to the Privacy Policy
                  </label>
                  <p id="privacy-description" className="text-gray-500">
                    <PrivacyPolicy />
                  </p>
                </div>
                <div className="ml-3 flex h-6 items-center">
                  <input
                    id="privacy"
                    aria-describedby="privacy-description"
                    name="privacy"
                    type="checkbox"
                    checked={privacyAgreed}
                    onChange={(e) => setPrivacyAgreed(e.target.checked)}
                    className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                  />
                </div>
              </div>
            </div>
          </fieldset>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-sky-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
