import React from "react";
import { queue } from "../../state/queue";
import { QueueItem } from "./queueItem";

export function Queue() {
  return (
    <ul className="divide-y divide-gray-800 pt-1">
      {queue.value.map((song, index) => (
        <QueueItem key={index + song.songId} song={song} index={index} />
      ))}
    </ul>
  );
}
