import React from "react";
import { ownPlaylists } from "../../../state/playlists";
import {
  addSongToPlaylist,
  removeSongFromPlaylist,
} from "../../../api/playlist";
import { currentSong } from "../../../state/player";
import clsx from "clsx";

export function CurrentSongPlaylistPicker({
  isExpanded,
  onChangeExpandedSubMenu,
}: {
  isExpanded: boolean;
  onChangeExpandedSubMenu: (subMenu: "tags" | "playlists" | null) => void;
}) {
  const handleAddSongToPlaylist = (playlistTitle: string) => {
    if (!currentSong.value) return;
    addSongToPlaylist(currentSong.value, playlistTitle);
  };

  const handleRemoveSongFromPlaylist = (playlistTitle: string) => {
    if (!currentSong.value) return;
    removeSongFromPlaylist(currentSong.value, playlistTitle);
  };

  const handleToggleExpanded = () => {
    onChangeExpandedSubMenu(isExpanded ? null : "playlists");
  };

  return (
    <>
      <button
        onClick={handleToggleExpanded}
        type="button"
        className={clsx(
          "relative inline-flex items-center w-full px-4 py-2 text-sm font-medium rounded-lg focus:z-10  hover:text-white hover:bg-slate-600 focus:text-white whitespace-nowrap justify-between",
          isExpanded && "bg-slate-600/70",
        )}
      >
        Add to playlist
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={clsx("w-5 h-5 -mr-3", isExpanded && "transform rotate-90")}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m8.25 4.5 7.5 7.5-7.5 7.5"
          />
        </svg>
      </button>
      {isExpanded && (
        <div className="py-1">
          {ownPlaylists.value.map((playlist) => {
            const isSongInPlaylist = playlist.songs.some(
              (song) => song.songId === currentSong.value?.songId,
            );
            return (
              <button
                key={playlist.title}
                onClick={() =>
                  isSongInPlaylist
                    ? handleRemoveSongFromPlaylist(playlist.title)
                    : handleAddSongToPlaylist(playlist.title)
                }
                type="button"
                className="relative inline-flex items-center w-full px-2 py-1 text-sm font-medium rounded-lg focus:z-10 hover:bg-slate-600/50 hover:text-white focus:text-white whitespace-nowrap uppercase"
              >
                <span className="flex items-center gap-2 max-w-full">
                  <div className="w-4 h-4 inline-block border border-slate-500 rounded flex-shrink-0">
                    {isSongInPlaylist && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4 inline-block text-white -mt-2 -ml-[1px]"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m4.5 12.75 6 6 9-13.5"
                        />
                      </svg>
                    )}
                  </div>

                  <span className="truncate">{playlist.title}</span>
                </span>
              </button>
            );
          })}
          <hr className="border-slate-600 mt-1" />
        </div>
      )}
    </>
  );
}
