import React from "react";
import { PlaylistSongListItem } from "./playlistSongListItem";
import { Playlist } from "common";

export function PlaylistSongList({ playlist }: { playlist: Playlist }) {
  return (
    <ul className="divide-y divide-slate-700/50">
      {playlist.songs.map((song) => (
        <PlaylistSongListItem
          key={song.songId}
          song={song}
          playlistId={playlist.title}
          playlistListener={playlist.listener}
        />
      ))}
    </ul>
  );
}
