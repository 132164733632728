import React from "react";
import { reorderSong, unqueueSong } from "../../api/song";
import { userName } from "../../state/global";
import { useDrag, useDrop } from "react-dnd";
import type { Identifier, XYCoord } from "dnd-core";
import { QueuedSong } from "common";
import { queue } from "../../state/queue";

export function QueueItem({
  song,
  index,
}: {
  song: QueuedSong;
  index: number;
}) {
  const ref = React.useRef<HTMLLIElement>(null);
  const [{ handlerId }, drop] = useDrop<
    { index: number; listener: string; originalIndex: number },
    void,
    { handlerId: Identifier | null }
  >({
    accept: "queueItem",
    canDrop: (item) => item.listener === userName.value,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: { index: number }, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      queue.value = queue.value.map((song, index) => {
        if (index === dragIndex) {
          return queue.value[hoverIndex];
        } else if (index === hoverIndex) {
          return queue.value[dragIndex];
        }
        return song;
      });

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop: (item) => {
      const dragIndex = item.originalIndex;
      const hoverIndex = index;
      reorderSong(dragIndex, hoverIndex);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "queueItem",
    item: () => {
      return {
        index,
        listener: song.listener,
        originalIndex: index,
        originalQueue: queue.value,
      };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => song.listener === userName.value,
    end: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (item && !didDrop) {
        queue.value = item.originalQueue;
      }
    },
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <li
      ref={ref}
      className="flex justify-between gap-x-6 py-1 group relative px-2 items-center"
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      <div className="flex min-w-0 gap-x-4">
        <img
          className="h-6 w-6 flex-none rounded-full drop-shadow-lg shadow-lg bg-gray-800"
          src={song.image}
          alt=""
        />
        <div className="min-w-0 flex-auto">
          <p className="text-sm font-semibold leading-6 text-white truncate">
            {song.title}
          </p>
        </div>
      </div>
      <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
        <p className="text-xs leading-5 text-gray-400">{song.listener}</p>
      </div>
      <div className="hidden group-hover:block absolute top-0 bottom-0 left-0 right-0 bg-white/20 text-right z-10">
        {song.listener === userName.value && (
          <button
            onClick={() => unqueueSong(index)}
            className="bg-red-900 rounded-full p-1 mr-2 mt-[2px]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          </button>
        )}
      </div>
    </li>
  );
}
