import { effect, signal } from "@preact/signals-react";
import { PlayingSong } from "common";

export type Message = {
  id: string;
  user: string;
  message: string;
  timestamp: string;
  song: PlayingSong | null;
};

export const messages = signal<Message[]>([]);

effect(() => {
  if (
    messages.value &&
    document.visibilityState === "hidden" &&
    !document.title.startsWith("*")
  ) {
    // When a new message arrives.
    // If the document does not have focus, add a * to the title
    // If the star is already there, don't add another
    document.title = "*" + document.title;
  }
});
