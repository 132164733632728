import React from "react";
import { Playlists } from "./playlist/playlists";
import clsx from "clsx";
import { activePlaylist } from "../state/playlists";
import { SongSearch } from "./songSearch/songSearch";
import { PlaylistView } from "./playlist/playlistView";

export function MusicManager() {
  return (
    <div className="md:h-full md:max-h-full overflow-hidden flex">
      <Playlists />
      <div className="h-full flex-[1]">
        <div className={clsx("grid grid-cols-1 md:h-full md:max-h-full")}>
          {activePlaylist.value ? <PlaylistView /> : <SongSearch />}
        </div>
      </div>
    </div>
  );
}
