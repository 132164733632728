import React from "react";
import { listeners } from "../state/global";

export function ListenerList() {
  return (
    <div className="w-full overflow-x-auto overflow-y-hidden bg-gray-800 text-center border-b border-slate-700">
      <div className="flex text-center justify-center items-center">
        {listeners.value.map((listener, i) => (
          <div
            key={listener}
            className="my-1 flex items-center gap-x-1.5 max-w-full px-2"
          >
            <div className="flex-none rounded-full bg-emerald-500/20 p-1">
              <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
            </div>
            <p className="text-xs leading-5 text-gray-500 truncate">
              {listener}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
