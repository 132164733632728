import React from "react";
import { currentSong } from "../../state/player";
import { ToggleFavoriteSongButton } from "./toggleFavoriteSongButton";
import { ToggleLikeSongButton } from "./toggleLikeSongButton";
import { CurrentSongMoreButton } from "./currentSongMoreButton/currentSongMoreButton";

export function CurrentSongBar() {
  return currentSong.value ? (
    <div className="flex justify-between gap-3 border-b border-slate-700 items-center p-3 relative drop-shadow shadow-sm bg-white/5">
      <div className="truncate">
        <div className="text-xs font-bold truncate">
          <span className="capitalize">{currentSong.value.listener}</span> is
          playing
        </div>
        <div className="truncate text-sm" title={currentSong.value.title}>
          {currentSong.value.title}
        </div>
      </div>
      <div className="whitespace-nowrap">
        <ToggleFavoriteSongButton song={currentSong.value} />
        <ToggleLikeSongButton />
        <CurrentSongMoreButton />
      </div>
    </div>
  ) : null;
}
