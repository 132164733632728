import React from "react";
import { ReactComponent as Logo } from "./logo.svg";
import { MusicManager } from "./components/musicManager";
import { Chat } from "./components/chat/chat";
import { Queue } from "./components/queue/queue";
import { ListenerList } from "./components/listenerList";
import { connectionState, userName, videoCannotPlay } from "./state/global";
import { VideoCannotPlayWarning } from "./components/videoCannotPlayWarning";
import { CurrentSongBar } from "./components/currentSong/currentSongBar";
import { SongPreview } from "./components/songPreview";
import { Tos } from "./components/tos";
import { CurrentSongVideo } from "./components/currentSong/currentSongVideo";

function App() {
  // When window gets visible, clear the "*" from the title
  // (* indicates that there are unread messages in the chat)
  React.useEffect(() => {
    const clearTitle = () => {
      if (document.title.startsWith("*")) {
        document.title = document.title.slice(1);
      }
    };
    window.addEventListener("focus", clearTitle);
    return () => {
      window.removeEventListener("focus", clearTitle);
    };
  }, []);

  const handleLogOut = () => {
    userName.value = null;
  };

  return (
    <div className="flex h-full flex-col relative">
      <header className="shrink-0 bg-gray-900">
        <div className="mx-auto flex h-16 items-center justify-between px-4 sm:px-6 lg:px-8 shadow-md drop-shadow-md">
          <Logo className="h-[50px] w-[50px]" />
          <div className="text-center">
            <div className="flex items-center space-x-3">
              {videoCannotPlay.value && <VideoCannotPlayWarning />}
              <Tos />
            </div>
            {connectionState.value === "Disconnected" && (
              <span className="text-red-500 text-xs">Disconnected</span>
            )}
          </div>
          <div className="flex items-center gap-x-8">
            <button
              onClick={handleLogOut}
              className="-m-1.5 p-1.5 text-sm underline group"
            >
              <span className="group-hover:hidden">{userName.value}</span>
              <span className="hidden group-hover:block">Log out</span>
            </button>
          </div>
        </div>
      </header>
      <div className="w-full md:grid md:grid-cols-3 divide-x divide-gray-700 md:h-[calc(100%-64px)] relative">
        <MusicManager />
        <SongPreview />
        <div className="bg-gray-700/30 flex flex-col md:max-h-full md:h-full overflow-hidden">
          <div>
            <CurrentSongVideo />
          </div>
          <CurrentSongBar />
          <div className="overflow-y-auto flex-grow">
            <Queue />
          </div>
        </div>
        <div className="flex flex-col max-h-[400px] md:max-h-full md:h-full overflow-hidden">
          <ListenerList />
          <Chat />
        </div>
      </div>
    </div>
  );
}

export default App;
