import parseMessage from "common/dist/parseMessage";
import { listeners, useMockApi, connectionState } from "../state/global";
import { handleChatEvents } from "./chat";
import { handlePlaylistEvents } from "./playlist";
import { handleSongEvents } from "./song";
import { handleListenerEvents, joinListener } from "./listener";

export let ws: undefined | WebSocket;
export const createWebSocketClient = () => {
  if (useMockApi.value) {
    // Simulate ListenerList
    listeners.value = ["Test1", "Test2", "Test3"];
    return;
  }
  if (ws) {
    return;
  }
  ws = new WebSocket("wss://stuenbe.tjb.dk/websocket");
  ws.addEventListener("open", () => {
    connectionState.value = "Connected";
    joinListener();
  });

  ws.addEventListener("message", (ev: MessageEvent<any>) => {
    let socketMessage = parseMessage(ev.data.toLocaleString());
    if (socketMessage) {
      handleChatEvents(socketMessage);
      handleListenerEvents(socketMessage);
      handleSongEvents(socketMessage);
      handlePlaylistEvents(socketMessage);
    }
  });

  ws.addEventListener("close", () => {
    connectionState.value = "Disconnected";
  });
};

export const closeWebSocketClient = () => {
  ws?.close();
  ws = undefined;
};
